<template>
  <div class="content">
    <Header title="付款码" bgColor="#e2bd7b" />
    <div class="top-img"></div>
    <div class="code">
      <div class="code-img">
        <img :src="code" alt="" />
      </div>

      <div class="code-info">
        <div class="title">
          <div class="icon">
            <img src="@/assets/customer/qianbao.png" alt="" />
          </div>
          {{ fin.customerMobile }}
        </div>
        <div class="value">¥{{ fin.balance }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import Header from './Header'
import { FinAccountClass } from '@/apis/fin'

const api = new FinAccountClass()
export default defineComponent({
  components: {
    Header
  },
  setup () {
    const fin = ref({})
    const code = ref('')

    const onGetPayCode = (id) => {
      api.payCode(id).then((resp) => {
        code.value = 'data:image/jpeg;base64,' + resp
      })
    }

    const onGetFin = (id) => {
      api.detail(id).then((resp) => {
        fin.value = resp
      })
    }

    return {
      fin,
      code,
      onGetPayCode,
      onGetFin
    }
  },

  created () {
    const id = this.$route.params.id
    this.onGetFin(id)
    this.onGetPayCode(id)
  }
})
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background: #ffffff;
  .top-img {
    background: url(../../assets/customer/my-bg.png) no-repeat bottom center;
    background-size: 100% auto;
    height: 3.2rem;
  }

  .code {
    padding: 0 0.3rem;
    margin-top: -2rem;
    .code-img {
      background: #fff;
      padding: 0.2rem;
      border-radius: 0.3rem;
      box-shadow: 0 0 0.1rem rgba(50, 50, 50, 0.1);
      img {
        display: block;
        width: 100%;
      }
    }

    .code-info {
      background: #fff;
      border-radius: 0.3rem;
      box-shadow: 0 0 0.1rem rgba(50, 50, 50, 0.1);
      padding: 0.2rem;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #ceb175;
      font-size: 0.36rem;
      margin-top: 0.5rem;
      .title {
        display: flex;
        align-items: center;
        .icon {
          width: 0.8rem;
          height: 0.8rem;
          margin-right: 0.2rem;
          img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
